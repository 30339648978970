<template>
    <div class="mt-1">

        <div class="loadRooms" v-if="isLoadingRooms">
            <b-spinner label="Spinning"></b-spinner>
            <p>Cargando habitaciones</p>
        </div>

        <div v-if="rooms.length && showRooms">
            <h4 class="mb-1">Habitaciones</h4>
            <div class="rooms-head mb-2">
                <h5> {{ contract.resortsname }} | {{ contract.housingnumber }}  | {{ contract.membershipcode }} | {{ contract.categoryroomName }}</h5>

                <b-badge href="#" pill class="bg-primary">
                  <feather-icon icon="CheckCircleIcon"/> Vigencia del contrato: {{ contract.dateStart }} {{ contract.dateEnd }}
                </b-badge>
            </div>

            <div  class="border p-1 mb-1" v-for="(room, index) in rooms" :key="index">
                <b-row>
                    <div class="col-xs-6 col-md-5 col-sm-12">
                        <ImgRoom :room="room"/>
                    </div>
                    <div class="col-xs-6 col-md-7 col-sm-12 data__room">

                            <h6 class="d-flex justify-content-between">
                                <b class="text-body"> {{ room.Name }} </b>
                                <b-badge :variant=" contract.categoryroomName == room.RoomTypeCode ? 'success':'warning' " v-if="contract" class="mb-1">
                                <feather-icon icon="AlertCircleIcon" class="mr-25" />
                                <span> Habitacion del propietario: {{ contract.categoryroomName }}</span>
                            </b-badge>
                            </h6>

                            <Rates :room="room" @set-rate-selected="setRateSelected"/>

                            <div v-if="room.listBenefits_Contract.length > 0">
                                <hr>

                                <div class="d-flex justify-content-between benefit-info">
                                    <div>
                                        <p><b>Código del beneficio: </b> {{room.listBenefits_Contract[0].benefitCode}}</p>
                                        <p><b>Total de Noches: </b> {{room.listBenefits_Contract[0].total}}</p>
                                    </div>

                                    <div>
                                        <p><b>Noches disponibles: </b> {{room.listBenefits_Contract[0].saldo}}</p>
                                        <p > <b>Noches a usar: </b> {{room.upgradesRoomsInfo}}</p>
                                    </div>

                                    <div>
                                        <p><b>Noches usadas: </b> {{room.listBenefits_Contract[0].used}}</p>
                                    </div>
                                </div>
                                <hr>
                            </div>

                            <div v-if="room.listBenefits_Xtra.length > 0">
                                <div class="d-flex justify-content-between benefit-info">
                                    <div>
                                        <p><b>Código del beneficio: </b> {{room.listBenefits_Xtra[0].benefitCode}}</p>
                                        <p><b>Total de Noches: </b> {{room.listBenefits_Xtra[0].total}}</p>
                                    </div>

                                    <div>
                                        <p><b>Noches disponibles: </b> {{room.listBenefits_Xtra[0].saldo}}</p>
                                        <p><b>Noches a usar: </b> {{room.upgradesRoomsInfo}}</p>
                                    </div>

                                    <div>
                                        <p><b>Noches usadas: </b> {{room.listBenefits_Xtra[0].used}}</p>
                                    </div>
                                </div>
                            </div>


                            <div class="alertas mt-1" v-if="!!mealPlanBenefit(room)">
                                <div class="alert-msg alert-msg__warning">
                                    <p class="alert-p text-warning"> <feather-icon icon="CircleIcon" class="mr-50" /> Al seleccionar un plan de alimentos, se seleccionará el beneficio correspondiente automaticamente</p>
                                </div>
                            </div>

                            <div class="alertas mt-1" v-if="bookingAvailibility">
                                <div class="alert-msg alert-msg__danger" v-if="bookingAvailibility.authorization">
                                    <p class="alert-p text-danger"> <feather-icon icon="CircleIcon" class="mr-50" /> La reserva tendra que pasar por una autorización previa para poder confirmarla</p>
                                </div>
                                <div class="alert-msg alert-msg__warning" v-if="bookingAvailibility.message !='' ">
                                    <p class="alert-p text-warning"> <feather-icon icon="CircleIcon" class="mr-50" /> {{ bookingAvailibility.message }}</p>
                                </div>
                                <div class="alert-msg alert-msg__warning" v-if="bookingAvailibility.additional==true">
                                    <p class="alert-p text-warning"> <feather-icon icon="CircleIcon" class="mr-50" /> La reserva se tomará como adicional</p>
                                </div>
                                <div class="alert-msg alert-msg__warning" v-if="bookingAvailibility.blackoutValid">
                                    <p class="alert-p text-warning"> <feather-icon icon="CircleIcon" class="mr-50" /> La reserva tiene un blackout</p>
                                </div>
                            </div>

                            <div class="bntToAddCart">
                                <b-button
                                    variant="primary"
                                    @click="addToCart(room)"
                                    :disabled="room.upgradesRoomsInfo == 0"
                                >
                                    <feather-icon icon="ShoppingCartIcon" size="16" /> Añadir
                                </b-button>
                            </div>
                    </div>
		        </b-row>
            </div>

        </div>
    </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import ImgRoom from '@/modules/fivesClub/components/catalogs/ownersBookings/requests/ImgRoom'
import Rates from '@/modules/fivesClub/components/catalogs/ownersBookings/requests/Rates'
import { formatGuestsInBooking } from '@/helpers/shopHelper'
import { market } from '@/modules/shop/mixins/market'

import { toJson } from '@/helpers/helpers'


export default {
    mixins: [ market ],
    props:{
        isLoadingRooms:{
            type: Boolean,
            required: true
        },
        rooms:{
            type: Array,
            required: true
        },
        benefits:{
            type: Array,
            required: true
        },
        benefitSelected:{
            type: Object,
            default: null
        },
        contract:{
            type: Object,
            default: null
        },
        bookingAvailibility:{
            type: Object,
            default: null
        },
        bookingRequest:{
            type: Object,
            default: null
        },
        owner:{
            type: Object,
            default: null
        },
        showRooms: {
            type: Boolean,
            required: true
        }

    },
    components: {
        ImgRoom,
        Rates,

    },
    data(){
        return {
            imgUrl: `https://backsales.thefives.com.mx/media/imgdefault.jpg`,
            currentYear: new Date().getFullYear(),
            benefitsLists: [
                {id: 'NTC', code: 'NOCHESXC', name: 'Noches por Contrato', selected: true},
                {id: 'NTX', code: 'NOCHESXTRA', name: 'Noches Extra', selected: true}
            ],
        }
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapState("shop", ["currency"]),
        ...mapState('start', ['hotels']),
    	...mapState('fivesClubCatalogs', ['benefitsToUseInBooking']),		        
        membership(){
            return this.bookingRequest?.membership
        }
    },
    methods:{
        ...mapActions('fivesClubCatalogs', ['saveOwnerBooking',]),
        ...mapMutations('shop', ['setInfoClient','addBookingInCartOwners', 'setIdConfigurationRoom', 'setBenefitNightsOwnerBooking', 'setOwners', 'setBenefitSelectedInOwnerBooking']),
        handlerGetBenefits(idOwner){
            const payload = { numberHouse: this.idHousing, idOwner, year: this.currentYear }
            this.$emit('get-benefits', payload)
        },
        setRateSelected(data){
            const { detailSelected, uuid, divisa } = data
            const idx = this.rooms.findIndex( room => room.uuid === uuid ) //indice room
            this.rooms[idx].detailSelected = detailSelected
            this.rooms[idx].detailSelected.divisa = divisa
            this.rooms[idx].priceInit = detailSelected.AmountAfterTax
        },
        async addToCart(hab){
            const room = toJson(hab)
            const { detailSelected, multimedias, RoomTypeCode, RoomTypeName,
                upgradesRoomsInfo, nightsContract, nightsXtra, idconfigurationRoom,
                listBenefits_Contract, listBenefits_Xtra, rates } = room

            const {  needAuthorization, reasonAuthorization, vendorcode, hotel,
                    resortId, owner, adults, children, dateIn, dateOut, ninos, roomType, nights, occupantType,
                    internalNotes, isguest, origin, membership } = this.bookingRequest //todo trabajar con owner para el payload

            const { InfoRates, RatePlanCode, PromotionCode, RatePlanID, TotalOffersApplied, MealPlanDescription, MealPlanCodes, ASIBenefit} = detailSelected

            const hotelName = this.hotels.find( hotel => hotel.id === resortId ).name

            let benefitSelected = this.benefits[0] || null
            if (membership == 3 && this.benefits.length>0){
                benefitSelected = this.benefits.find(benefit => benefit.code == ASIBenefit)
            }

            const bookingData = {
                nightsContract, nightsXtra, idconfigurationRoom,
                hasBothNights: !!nightsContract && !!nightsXtra,
                nights,
                adults,
                numberChildren:children,
                ninos: toJson(ninos),
                idioma: this.owner?.language == 1 ? "en" : "es",
                dateIn,
                dateOut,
                categoryName: "ReservasOwners",
                descmax: 0,
                modelType: "booking",
                isEdit: 'false',
                balanceNights: 0,
                //datos tomados de room
                detaildisplay: `${RoomTypeCode || ''} | ${RoomTypeName || ''}`,
                img: (multimedias?.length > 0 && multimedias[0]?.multimedia != '' ) ? multimedias[0]?.multimedia : this.imgUrl,
                multimedias: multimedias?.length > 0 ? toJson(multimedias) : null,
                extrapax: false,
                detailId: RoomTypeCode,
                RoomTypeCode,
                nightsUpgrade: upgradesRoomsInfo,

                //se toma datos del owner
                email: owner?.ownerEmail,
                name: owner?.ownerName,
                lastname: owner?.ownerLastname,
                phone: owner?.ownerPhone,
                //del rate seleccionado, detailSelected
                infoRates: InfoRates,
                tarifa: RatePlanCode,
                promoCode: PromotionCode,
                // rateplanid: RatePlanID,
                rateManual: "",
                rateInit: Math.abs(TotalOffersApplied),
                priceInit: Math.abs(TotalOffersApplied),
                AmountAfterTax: Math.abs(TotalOffersApplied),
                saleprice: Math.abs(TotalOffersApplied),
                rateplanname: MealPlanDescription,
                roomtypename: RoomTypeName,
                serviciosihot: null,

                term: { id: 8, title: "	Política Propietarios" },
                needAuthorization,
                reasonAuthorization,
                vendorcode,
                idHotel: resortId,
                hotel,
                // campos faltantes
                dateAuthorization: null,
                // this.hotels.find( hotel => hotel.id === resortId ).name
                hotelName,
                idUserAuthorizeRate: null,
                isRateLimit: false,
                requiresAuthorization: false,
                urlAuthorization: "",
                MealPlanCodes,
                MealPlanDescription,
                PromotionCode,
                isPromo: false,
                manualPrice: "",
                ageChildren: ninos, //llenar con niños, sus edades
                language: this.owner?.language == 1 ? "en" : "es",
                rangeDate: `${dateIn} to ${dateOut}`,
                roomType,
                isManualRate: false,
                show: false,
                manualRate: "",
                rate: "",
                roomCode: RoomTypeCode,
                children,
                promotionCode: PromotionCode,
                salepriceWithDiscount: Math.abs(TotalOffersApplied),
                originalSalePrice: Math.abs(TotalOffersApplied),
                qty: 1,
                cost: "0.00",
                isBenefit: false,
                vendor: vendorcode,
                occupanttype: null,
                ownerBooking: null,
                adultos: adults,
                RatePlanCode,
                RatePlanID,
                house: this.idHousing,
                owners: this.contract?.owners,

                // extradata
                owner,
                internalNotes,
                membership,
                isguest,
                origin,
                housingid: this.contract.housingid,
                contract: this.contract.id,
                contractData: this.contract,
                housingnumber: this.contract.housingnumber,
                bookingAvailibility: this.bookingAvailibility?.additional ? 2 : 1, // si addtional es true tomo 2, 1 Principal, 2 Adicional
                idUser: this.user.idUser,
                idOwnerBooking: null,
                selectedOwner: null,
                CurrencyCode: 'USD',
                displayrates: {
                    AmountAfterTax: Math.abs(TotalOffersApplied),
                    currency: this.currency.code
                },
                benefitSelected,
                bothBenefits: listBenefits_Contract.length > 0 && listBenefits_Xtra.length > 0 ? [...listBenefits_Contract, ...listBenefits_Xtra] : null,
            }

            if (membership == 3 && bookingData.hasBothNights){
                const choosedBenefit = this.benefitsLists.find((benefit)=> benefit.code == ASIBenefit)
                choosedBenefit.selected = true
                delete choosedBenefit.code
                this.setBenefitSelectedInOwnerBooking(choosedBenefit)
                bookingData.hasBothNights = false
                bookingData.bothBenefits = null
                bookingData.nightsContract = choosedBenefit.id == 'NTC'
                bookingData.nightsXtra = choosedBenefit.id == 'NTX'
            }

            const payload = formatGuestsInBooking(bookingData)
            payload.benefitsToUseInBooking = this.payloadBenefits()
            const count = payload.benefitsToUseInBooking.filter( item => parseFloat(item.qty) > parseFloat(item.balance) ).length
            if( count > 0){
				this.$swal.fire({ icon: "warning", title:'Beneficios', text:'Tienes beneficios seleccionados que superan el balance' })
                return
            }
           
            await this.manageAddProductInCart(payload, payload.categoryName)

            const { categoryroomName, housingcode, housingid, housingname, housingnumber, owners, resortsid, resortsname } = this.contract
            this.setOwners({categoryroomName, fullname: housingname, housingcode, housingid, housingname, housingnumber, owner: owners, resortid:resortsid, resortname:resortsname})

            this.setInfoClient({
                name: owner.ownerName,
                lastname: owner.ownerLastname,
                email: owner.ownerEmail,
                phone: owner.ownerPhone,
                id: owner.idOwner
            })

            this.$router.push({ name: "order-summary" });
        },
        mealPlanBenefit(room){
            const ASIBenefit = room.detailSelected.ASIBenefit
            return ASIBenefit ? this.benefits.find(benefit => benefit.code == ASIBenefit) : null
        },
        payloadBenefits(){
			const benefits = this.benefitsToUseInBooking
			.filter(item => item.qty > 0)
			.map(item => {
				return {					
					balance: item.balance,
					benefit: item.benefit,
					createdBy: this.user.idUser, //116,
					owner: this.owner.idOwner,
					ownerbooking: null,
					transaction: item.transaction,
					qty: item.qty,					
				}
			})
			return benefits
		}
    }
}
</script>

<style scoped>

.loadRooms{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 1rem;
}

.rooms-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}


.container-rooms {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

.card-house {
  background-color: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
}

.card-house img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-house .content-room {
  padding: 20px;
}

.card-house .title-room {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}
.content-room__title{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-house .btn-add-to-cart {
  display: inline-block;
  background-color: #333366;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  border: 0;
}

.content-room__cta {
    margin-top: 2rem;
    text-align: right;
}

.card-house .btn-add-to-cart:hover {
  background-color: #1c1c3d;
  box-shadow: 0 5px 10px rgba(0,0,0,0.3);
}

.bntToAddCart{
    /* margin-top: 3rem; */
    display: flex;
    flex-direction: row-reverse;
}

.alert-p{
	margin: 0;
	/* font-size: 1rem; */
	font-weight: 500;
}
.alertas{
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    flex-direction: column;
    margin: 0;
}
.alert-msg{
    /* max-width: 920px; */
    /* width: 400px; */

    /* text-align: center; */
    color: #212121;
    /* // padding: .5rem 1rem; */
    font-size: .95rem;
}

.benefit-info p {
    margin: 0;
}
</style>