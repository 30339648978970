<template>
    <div>
        <b-row v-if="benefitsFirstGroup.length > 0 ||  benefitsSecondGroup.length > 0">
            <b-col md="12" >
                <b-alert show variant="primary">
                    <div class="alert-body">
                        <span>Los beneficios que se seleccionen se aplicarán cuando el huesped sea un Invitado</span>
                    </div>
                </b-alert>
            </b-col>
        </b-row>

        <div class="border-benfits-membership">
          
            <app-collapse >
                <app-collapse-item title="Beneficios a autorizar en la reserva">   
                            
                    <b-row>
                        <b-col md="6" v-if="benefitsFirstGroup.length > 0">
                            <h6 class="subtitle font-weight-bolder mb-2"> Beneficios {{  yearsBenefitsList[0] }}</h6>
                            <div class="table-responsive">
                                <table class="table table-sm">
                                    <thead>
                                        <tr>
                                            <th> Beneficio </th>												
                                            <th class="centerT">Balance </th>
                                            <th class="centerT"> Cantidad a autorizar </th>
                                        </tr>
                                    </thead>
                                    
                                    <tbody>																                         
                                        <tr v-for="(benefit, index) in benefitsFirstGroup" :key="index">
                                            <td>
                                                {{benefit.benefitname}}
                                            </td>
                                        
                                            <td class="centered-cell">
                                                {{ benefit.balance }}
                                            </td>
                                            <td class="centered-cell">
                                                <b-form-input
                                                    
                                                    size="sm"
                                                    type="number"
                                                    placeholder="Cantidad"	
                                                    :max="benefit.balance"
                                                    v-model="benefit.qty"     
                                                    @input="setQtyBenefit(benefit)"                                   
                                                />	                                                                                  
                                            </td>
                                        </tr>								
                                    </tbody>										
                                </table>	
                            </div>
                        </b-col> 
                        <b-col md="6" v-if="benefitsSecondGroup.length > 0">
                            <h6 class="subtitle font-weight-bolder mb-2"> Benefits {{  yearsBenefitsList[1] }}</h6>
                            <div class="table-responsive">
                                <table class="table table-sm">
                                    <thead>
                                        <tr>
                                            <th> Beneficio </th>												
                                            <th class="centerT">Balance </th>
                                            <th class="centerT"> Cantidad a autorizar </th>
                                        </tr>
                                    </thead>
                                    
                                    <tbody>																
                                    
                                        <tr v-for="(benefit, index) in benefitsSecondGroup" :key="index">

                                            <td>
                                                {{benefit.benefitname}}
                                            </td>
                                        
                                            <td class="centered-cell">
                                                {{ benefit.balance }}
                                            </td>
                                            <td class="centered-cell">
                                                <b-form-input                                                
                                                    size="sm"
                                                    type="number"
                                                    placeholder="Cantidad"	
                                                    :max="benefit.balance"
                                                    v-model="benefit.qty"     
                                                    @input="setQtyBenefit(benefit)"                                   
                                                />	                                                                                        
                                            </td>
                                        </tr>								
                                    </tbody>										
                                </table>	
                            </div>
                        </b-col>            
                    </b-row>
                </app-collapse-item>
            </app-collapse>
        </div>
    </div>
   
</template>

<script>
import { mapState, mapActions, mapMutations , mapGetters } from "vuex"
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue"
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue"

export default {
	components: {
        AppCollapse,
        AppCollapseItem,   
    },
    computed: {		
		...mapState('fivesClubCatalogs', ['yearsBenefitsList']),	
        ...mapGetters('fivesClubCatalogs', ['benefitsFirstGroup', 'benefitsSecondGroup' ]),        	        
      
	},
	methods: {
		...mapActions('fivesClubCatalogs', ['saveBenefitsBookingOwner', 'updateBenefitsBookingOwner']),
        ...mapMutations('fivesClubCatalogs',['setQtyBenefitToUseInBooking']),		
        setQtyBenefit( data ){
            const { transaction, qty } = data
            this.setQtyBenefitToUseInBooking({transaction, qty })
        }
	},
}
</script>
<style scoped>
    .centered-cell, th.centerT {
        text-align: center; /* Centrado horizontal */
        vertical-align: middle; /* Centrado vertical */
    }

    td input {
        margin: 0 auto;
        display: block;
    }

    .border-benfits-membership {
        border: 1px solid #333366;
        margin-bottom: 2.5rem;
    }
</style>